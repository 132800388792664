html {
  height: 100%;
  background-color: white;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #008cff;
}

#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiDataGrid-row:hover {
  cursor: "pointer";
  background-color: green;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  min-height: 200px;
}

/* TODO: Added initially for issue 577, to be applied to similar ux for form warnings */
