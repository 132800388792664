.swal2-title {
    padding: 4px !important;
    margin: 0px !important;
    text-align: left !important;
}

.swal2-html-container {
    padding: 4px !important;
    margin: 0px !important ;
}

.swal2-actions {
    padding: 5px !important;
    margin: 0px !important;
}

.swal2-actions.button {
    border: 10px solid #00ff00 !important;
}

.swal2-confirm {
    margin: 0px !important;
}
