/*
 * Apply this class to a tooltip object which is contained inside a parent with
 * the "tooltip-container" class.
 * In addition to the "tooltip" class on the tooltip object, also use the
 * "tooltip-{top,bottom,right,left}" classes to position the tooltip object
 * relative to the container.
 * */

.tooltip {
  visibility: hidden;
  position: absolute;
}

.tooltip-top {
  bottom: 100%;
}

.tooltip-content {
  border: 2px solid rgb(107,114,128);
  border-radius: 0.375em;

  /* these are just to make the default sensible;
   * background and text colors can be overridden; */
  background: white;
  color: black;
}

/* this adds the arrow pointing to the tooltip container below */
.tooltip-top::after {
  content: "";
  height: 10px;
  width: 10px;
  display: block;
  transform: rotate(45deg);
  position: relative;
  z-index: -1;
  bottom: 5px;
  margin-left: 7px;

  background: rgb(107,114,128);
}


.tooltip-top-left {
  bottom: 100%;
  right: 20%;
}


.tooltip-top-left::after {
  content: "";
  height: 10px;
  width: 10px;
  display: block;
  transform: rotate(45deg);
  position: relative;
  z-index: -1;
  bottom: 5px;
  margin-left: 235px;

  background: rgb(107,114,128);
}


.tooltip-bottom {
  top: 100%;
}

.tooltip-bottom::before {
  content: "";
  height: 10px;
  width: 10px;
  display: block;
  transform: rotate(45deg);
  position: relative;
  z-index: -1;
  bottom: -5px;
  margin-left: 12px;

  background: rgb(107,114,128);
}

.tooltip-right {
  left: 100%;
}

.tooltip-left {
  right: 100%;
}

.tooltip-container {
  position: relative;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  z-index: 100;
}
