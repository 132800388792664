
/* FORM CLASSES */


.form-alert, .form-warning {
    padding: 10px; 
    border-radius: 6px;
    width: 75%;
    margin:auto;
  }
  
  .form-alert {
    background-color: #fae7e7;
    border: 1px solid #FDB4B4;
    color: #aa0000;
  }
  .form-alert > .learnLink {
    color: #066fc4 !important;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .form-warning {
    background-color: #FFFBE8;
    text-align: center;
    border: 1px solid #ddd5ae;
    color: #7b7662;
    padding: 10px;
    margin: auto;
  }
  

  input, select, textarea {
    border: 1px solid #666666;
  }


  .std-button {
      background-color: #e0e0e0;
      color: #333333;
      padding: .5em 1em .5em 1em;
      margin-left: 3px;
      margin-right: 3px;
      white-space: nowrap;
      border-radius: 4px;
      font-size: .85em;
      line-height: 1.2em;
      text-transform: uppercase;
      font-weight:500;
      -webkit-font-smoothing: antialiased;
      min-width: 110px;
  }

  .std-button:disabled {
    opacity: 25%;
    cursor: not-allowed;
  }

  .std-button:hover {
    background-color: #333333;
    color: #e0e0e0;
  }

  .std-button-thin {
    max-width: 2em !important;
    min-width: 48px !important;
  }


  input[type=checkbox] {
    margin: 4px !important;
  }

  input[type=radio] {
    margin-right: 4px;
    margin-left: 8px;
  }


  .modal-popup-form {
    padding: 20px;
  }

  .MuiFab-root {
    margin: 10px !important;
  }

  /* For toggle slider */
  input:checked ~ .dot {
    transform: translateX(100%);
    background-color: #2a3647;
  }
