.icon-base {
  @apply h-5;
  @apply w-5;
}

.icon-sm {
  @apply h-4;
  @apply w-4;
}

.icon-lg {
  @apply h-6;
  @apply w-6;
}

.icon-xl {
  @apply h-7;
  @apply w-7;
}
